import React, { Fragment, useEffect } from "react";
import MetaTags from "react-meta-tags";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import Content from "../sections/home/Content";
import AlertBanner from "../layouts/AlertBanner";
import { useLocation } from "react-router-dom";

function Home() {
  const location = useLocation();

  const pageTitle =
    "Working Together to Do Better | Clackamas County Democrats";

  useEffect(() => {
    const url = `https://clackamasdems.org/${location.hash}${location.search}`;
    const dataLayer = {
      event: "virtualPageView",
      pageUrl: url,
      pageTitle: pageTitle,
    };
    window.dataLayer.push(dataLayer);
  }, [location]);

  return (
    <Fragment>
      <MetaTags>
        <title>{pageTitle}</title>
        <meta
          name="description"
          content="Get to know the Democratic Party of Clackamas County. News, events and volunteer information. Thinking of running for office, start here."
        />
      </MetaTags>
      <Header />
      <AlertBanner
        type="info"
        title="Get Ready to Vote!"
        message={`Get Ready to Vote! See our <a style="color: white;" href="/#/blog-posts/2024-09-16-endorsements-for-november-2024">Endorsements for the November 5th Elections<a/>.`}
      />
      <Content />
      <Footer />
    </Fragment>
  );
}

export default Home;
